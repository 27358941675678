// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$gray-900: #212529;

$rose: #e74c94;
$blue: #0d6efd;
$pink: #d63384;

$colors: (
  "rose": $rose
);

$primary: $gray-900;
$secondary: $rose;

// Links
//
// Style anchor elements.

$link-color: $primary;
$link-hover-color: $secondary;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,800&display=swap");
$font-family-montserrat: "Montserrat";
$font-family-base: $font-family-montserrat;
// stylelint-enable value-keyword-case

$font-size-base: 1rem;
$font-size-lg: $font-size-base * 2;

$font-weight-bold: 800;

$h1-font-size: $font-size-base * 5;

$h4-font-size: $font-size-base * 2;

// Links
//
// Style anchor elements.
$link-hover-decoration: none;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding: 0.5rem;
$table-cell-padding-sm: 0.25rem;

// Navbar
$navbar-nav-link-padding-x:         1rem;

$navbar-light-color: $primary;
$navbar-light-hover-color: $secondary;
$navbar-light-active-color: $secondary;
